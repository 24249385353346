import * as React from 'react';
import Layout from '../components/layout';

export default function ThankYou() {
    return(
        <Layout>
            <h1>Thank you!</h1>
            <p>I really appreciate it.</p>
            <p>You will receive an email receipt shortly, and an email tracking number upon shipment.</p>
            <p>In the meantime, you may want to read my <a href="/policy#shipping">shipping policy</a> and <a href="/policy#refund">refund policy.</a></p>
        </Layout>
    );
}